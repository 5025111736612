<template>
	<b-overlay :show="loading" rounded="sm">
		<b-row>
			<b-col cols="12">
				<!-- 1 -->
				<b-card no-body class="mb-4">
					<!-- Search Control -->
					<b-card-body class="p-3 d-flex justify-content-between">
						<b-form-group class="mb-2 w-25">
							<b-input-group>
								<b-form-input id="filter-input" v-model="filter" type="search" placeholder="Type to Search"></b-form-input>

								<b-input-group-append>
									<b-button variant="danger" :disabled="!filter" @click="filter = ''">Clear</b-button>
								</b-input-group-append>
							</b-input-group>
						</b-form-group>
					</b-card-body>
					<!-- End Section -->

					<!-- Data Table -->
					<b-table
						style="min-height: 250px;"
						show-empty
						responsive
						small
						class="mb-0"
						head-variant="light"
						:items="applications"
						:filter="filter"
						:fields="fields"
						hover
					>
						<template #cell(id)="data"> #{{ data.item.id }} </template>
					</b-table>
					<!-- End Section -->

					<!-- Pagination -->
					<div class="p-3 d-flex align-items-center">
						<b-form-group
							label="Per page"
							label-for="per-page-select"
							label-cols-sm="6"
							label-cols-md="4"
							label-cols-lg="9"
							label-size="sm"
							label-class="fw-medium"
							class="mb-0"
						>
							<b-form-select @change="getData" id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
						</b-form-group>
						<div class="ml-auto">
							<b-pagination @input="getData" v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" class="my-0"></b-pagination>
						</div>
					</div>
					<!-- End Section -->
				</b-card>
			</b-col>
		</b-row>
	</b-overlay>
</template>

<script>
export default {
	name: "applications",

	data: () => ({
		page: {
			title: "applications",
		},
		fields: [
			{
				key: "id",
				label: "ID",
				sortable: true,
			},
			{
				key: "firstName",
				label: "First Name",
				sortable: true,
			},
			{
				key: "lastName",
				label: "Last Name",
				sortable: true,
			},
			{
				key: "address",
				label: "Address",
				sortable: true,
			},
			{
				key: "amount",
				label: "Amount",
				sortable: true,
			},
			{
				key: "monthPayment",
				label: "Monthly Payment",
				sortable: true,
			},
			{
				key: "monthPeriod",
				label: "Period In Months",
				sortable: true,
			},
		],
		loading: false,
		filter: null,
		totalRows: 1,
		currentPage: 1,
		perPage: 10,
		pageOptions: [5, 10, 20, 40, { value: 100, text: "Show a lot" }],
		applicationSelected: null,
		applications: [],
		show: false,
	}),
	components: {},
	methods: {
		async getData() {
			this.loading = true;
			const res = await this.$store.dispatch("applications/get_allBuilders", { skip: this.currentPage, take: this.perPage });
			this.totalRows = res.dataRecords;
			this.applications = res.dataResult;
			this.loading = false;
		},
	},
	computed: {},
	mounted() {},
	created() {
		this.getData();
	},
};
</script>
